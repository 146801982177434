import { render, staticRenderFns } from "./leadership.vue?vue&type=template&id=4205c637&scoped=true&"
import script from "./leadership.vue?vue&type=script&lang=js&"
export * from "./leadership.vue?vue&type=script&lang=js&"
import style0 from "./leadership.vue?vue&type=style&index=0&id=4205c637&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4205c637",
  null
  
)

export default component.exports