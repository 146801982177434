<template>
	<div class="navigation-container">
		<div class="shadow-box">
			<div class="nav-item-box">
				<div class="nav-item" :class="activeId === 'home' ? 'nav-item-active': ''"
					@click="navItemClick('home')">首页</div>
				<div class="nav-item" :class="activeId === 'centerIntroduction' ? 'nav-item-active': ''"
					@click="navItemClick('centerIntroduction')">中心简介</div>
				<div class="nav-item" :class="activeId.indexOf('yxdongtai') > -1 ? 'nav-item-active': ''"
					@click="navItemClick('yxdongtai')">研修动态</div>
				<div class="nav-item" :class="activeId === 'netTransmit' ? 'nav-item-active': ''"
					@click="navItemClick('netTransmit')">网络发信</div>
				<div class="nav-item" :class="activeId === 'yxActivity' ? 'nav-item-active': ''"
					@click="navItemClick('yxActivity')">研修活动</div>
				<!-- <div class="nav-item" :class="activeId === 'yxActivity' ? 'nav-item-active': ''" @click="toCms()">内容管理
				</div> -->
				<div v-if="isLogin" class="nav-item" :class="activeId === 'yxActivity' ? 'nav-item-active': ''"
					@click="toCms()">我的研修
				</div>
			</div>
			<div class="nav-operate-box">
				<div class="search-input-box">
					<el-input class="custom-el-input" v-model="searchKey" placeholder="请输入内容"></el-input>
					<div class="search-btn" @click="navigatorSearch">
						<img style="width: 20px;height: 20px;vertical-align: middle" src="../assets/search.png" alt="">
					</div>
				</div>
				<div class="dropdown-div" style="width: 160px;cursor: pointer;text-align: center">
					<div class="loginBtn" style="display: flex;align-items: center;justify-content: center"
						v-if="!isLogin" @click="toLogin">
						<img style="width: 20px;height: 20px" src="../assets/yonghu.png" alt="">
						登录
					</div>
					<el-dropdown v-else placement="bottom-start">
						<div class="el-dropdown-link"
							style="width: 130px;display: flex;align-items: center;justify-content: center">
							<img style="width: 35px;height: 35px;border-radius: 35px" :src="userInfo.headPortrait"
								alt="">
							<div style="margin-left: 20px">{{ userInfo.trueName || userInfo.userName }}</div>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>
								<div class="dropdown-item" @click="toUserCenter">个人中心</div>
							</el-dropdown-item>
							<el-dropdown-item>
								<div class="dropdown-item" @click="toWebMessage">网络发信</div>
							</el-dropdown-item>
							<el-dropdown-item>
								<div class="dropdown-item" @click="toXiaoxi">消息公告</div>
							</el-dropdown-item>
							<el-dropdown-item>
								<div class="dropdown-item" @click="loginOut">退出登录</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import cookieUtil from "@/util/cookieUtil"
	export default {
		name: "navigation",
		data() {
			return {
				searchKey: '',
				activeId: '',
				isLogin: false,
				userInfo: {
					trueName: ''
				}
			}
		},
		mounted() {
			this.activeId = this.$route.name
			let JSESSIONID = cookieUtil.getCookie("JSESSIONID") || '';
			(JSESSIONID || this.$route.query.JSESSIONID) && this.$api.home.getUserInfo({}, false).then(res => {
				if (res.code == 200) {
					Object.assign(this.userInfo, res.result)
					this.isLogin = true
					cookieUtil.setCookie({
						key: 'userInfo',
						value: JSON.stringify(res.result)
					})
				}
			})
			let _searchKey = sessionStorage.getItem("searchKey")
			if (this.activeId === 'yxdongtai' && _searchKey) {
				this.searchKey = _searchKey
				sessionStorage.removeItem("searchKey");
				this.$emit('searchDondtai', this.searchKey)
			}
		},
		methods: {
			navigatorSearch() {
				let {
					name
				} = this.$route
				if (name === 'yxdongtai') {
					this.$emit('searchDondtai', this.searchKey)
				} else {
					sessionStorage.setItem("searchKey", this.searchKey);
					this.$router.push({
						name: 'yxdongtai'
					})
				}
			},
			loginOut() {
				cookieUtil.delCookie("JSESSIONID")
				cookieUtil.delCookie("userInfo")
				this.isLogin = false
				// location.href = `${location.origin}/`
				location.href = `https://www.tzjsyxw.cn/tzapi/user/logout?responseUrl=https://www.tzjsyxw.cn`
			},
			toLogin() {
				//tzjsyxw
				this.$toLogin('https://www.tzjsyxw.cn/')
				//zixxns
				// this.$toLogin('http://localhost:8080')
			},
			toCms() {
				//tzjsyxw
				window.open('https://www.tzjsyxw.cn/cms/ownCenter/web')
				//zixxns
				// window.open('https://yxw.zixxns.com/cms/ownCenter/web')
			},
			toUserCenter() {
				this.$router.push({
					name: 'userCenter'
				})
			},
			toXiaoxi() {
				this.$router.push({
					name: 'xiaoxi'
				})
			},
			toWebMessage() {
				this.$router.push({
					name: 'netMessage'
				})
			},
			navItemClick(path) {
				this.$router.push({
					name: path
				})
			}
		}
	}
</script>

<style lang="scss">
	.loginBtn:hover {
		color: dodgerblue;
	}

	.navigation-container {
		background-color: #f7f7f7;
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
		z-index: 9;
		overflow: auto;
		position: relative;
		height: 60px;

		.shadow-box {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: space-between;
			background: #FFFFFF;
			box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
		}

		.nav-item-box {
			display: flex;
			align-items: center;
			padding-left: 8px;

			.nav-item {
				min-width: 84px;
				padding: 0 14px;
				height: 40px;
				line-height: 40px;
				margin-right: 19px;
				color: #FFF;
				/*width: 124px;*/
				/*text-align: center;*/
				cursor: pointer;
				// background-image: linear-gradient(-180deg, #94D3FC 0%, #2C9FE8 100%);
				// background-image: linear-gradient(-180deg, #94D3FC 0%, #2C9FE8 100%);
				background-image: linear-gradient(-180deg, #0092D8, #0070B7);
				border: 1px solid #DCEEFF;
				box-shadow: 1px 0 4px 0 rgba(31, 79, 129, .5);
				border-radius: 3px;
			}

			/*.nav-item-active {*/
			/*color: #409EFF;*/
			/*}*/
			.nav-item:hover {
				background-image: linear-gradient(-180deg, #0092D8 0%, rgba(0, 112, 183, .85) 100%);
			}
		}

		.nav-operate-box {
			height: 60px;
			display: flex;
			align-items: center;
			position: relative;

			.search-input-box {
				height: 34px;
				display: flex;
				align-items: center;
				position: relative;
			}

			.custom-el-input {
				width: 360px;

				input {
					border-radius: 34px !important;
					height: 34px;
				}
			}

			.search-btn {
				width: 60px;
				height: 34px;
				line-height: 34px;
				border-radius: 34px;
				background-color: #409EFF;
				color: #fff;
				position: absolute;
				right: 0;
				top: 0;
				cursor: pointer;
				text-align: center;
			}
		}

		.dropdown-box {
			width: 160px;

			.dropdown-item {
				width: 120px;
				height: 40px;
				white-space: nowrap
			}
		}
	}
</style>