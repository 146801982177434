<template>
  <div class="organization-container">
    <div v-if="isCheckDetail" class="detail">
      <div class="title">
        {{ introduceTitle }}
      </div>
      <div class="time">
        发布时间： {{ createTime }}
      </div>
      <div id="content" class="wangEditor-content"></div>
    </div>
    <div v-else style="display: flex;flex-wrap: wrap">
      <div @click="checkDetail(item)" v-for="(item, index) in dataList" class="organization-item" :key="index">
        {{ item.introduceTitle }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "organization",
    props: {
      isCheckDetail: false
    },
    data() {
      return {
        dataList: [],
        introduceTitle: '',
        createTime: ''
      }
    },
    mounted() {
      this.selectCenterIntroduce()
    },
    methods: {
      checkDetail(item) {
        this.$emit('showDetail')
        this.$api.home.selectCenterIntroduceById({
          id: item.id
        }).then(res => {
          let { introduceTitle, introduceContent, createTime } = res.result
          this.createTime = createTime
          this.introduceTitle = introduceTitle
          document.getElementById('content').innerHTML = introduceContent || ''
        })
      },
      selectCenterIntroduce() {
        this.$api.home.selectCenterIntroduce({
          introduceType: '党建工会'
        }).then(res => {
          let {code, result} = res
          if (code == 200) {
            this.dataList = result.rows
            // result.rows && result.rows[0] && result.rows[0].id &&
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .organization-container {
    display: flex;
    padding: 30px;
    .detail {
      width: 100%;
    }
    .title {
      font-size: 22px;
      color: #1E1E1E;
      margin-bottom: 30px;
    }
    .time {
      font-size: 14px;
      color: #4A4A4A;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }
    .organization-item:hover {
      color: #00BCEA;
    }
    .organization-item {
      width: 150px;
      text-align: center;
      border-left: 5px solid #00BCEA;
      margin: 30px;
      cursor: pointer;
    }
  }
</style>