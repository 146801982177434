<template>
  <div class="leadership-container">
    <div v-if="isCheckDetail" style="display: flex" class="detail">
      <div class="img-box" style="width: 300px;padding: 8px;background: #F7F7F7;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);border-radius: 2px;">
        <img style="width: 100%" :src="backPic" alt="">
      </div>
      <div class="desc-box" style="flex: 1;padding: 0 20px">
        <div class="title">{{ introduceTitle }}</div>
        <div class="time">
          发布时间： {{ createTime }}
        </div>
        <div id="content" class="wangEditor-content"></div>
      </div>
    </div>
    <div style="display: flex;flex-wrap: wrap;padding-left: 30px" v-else>
      <div @click="checkDetail(item)" class="item-box" v-for="(item, index) in dataList" :key="index">
        <div class="image-box">
          <img :src="item.backPic" alt="">
        </div>
        <p class="introduceTitle">{{ item.introduceTitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "leadership",
    props: {
      isCheckDetail: false
    },
    data() {
      return {
        dataList: [],
        introduceTitle: '',
        createTime: '',
        backPic: ''
      }
    },
    mounted() {
      this.selectCenterIntroduce()
    },
    methods: {
      checkDetail(item) {
        this.$emit('showDetail')
        this.$api.home.selectCenterIntroduceById({
          id: item.id
        }).then(res => {
          let { introduceTitle, introduceContent, createTime, backPic } = res.result
          this.createTime = createTime
          this.backPic = backPic
          this.introduceTitle = introduceTitle
          document.getElementById('content').innerHTML = introduceContent || ''
        })
      },
      selectCenterIntroduce() {
        this.$api.home.selectCenterIntroduce({
          introduceType: '现任领导'
        }).then(res => {
          let {code, result} = res
          if (code == 200) {
            this.dataList = result.rows
            // result.rows && result.rows[0] && result.rows[0].id && this.$api.home.selectCenterIntroduceById({
            //   id: result.rows[0].id
            // }).then(res => {
            //   let { introduceTitle, introduceContent, createTime } = res.result
            //   this.createTime = createTime
            //   this.introduceTitle = introduceTitle
            //   document.getElementById('content').innerHTML = introduceContent || ''
            // })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.leadership-container {
  padding: 30px;
  .title {
    font-size: 22px;
    color: #1E1E1E;
    margin-bottom: 30px;
    text-align: left;
  }
  .time {
    font-size: 14px;
    color: #4A4A4A;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  .item-box {
    padding: 8px;
    margin: 23px;
    cursor: pointer;
    width: 178px;
    .introduceTitle {
      margin: 6px 0;
    }
    .image-box {
      height: 210px;
      padding: 8px;
      overflow: hidden;
      border: 1px solid #fff;
      img {
        height: 100%;
        width: auto;
        margin: auto;
      }
    }
  }
  .item-box:hover {
    .image-box {
      border: 1px solid #eee;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    }
    .introduceTitle {
      color: #00BCEA;
      margin: 6px 0;
    }
  }
}
</style>